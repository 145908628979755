import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { DocumentText1 } from "iconsax-react";

const Faq = () => {
  const [visible, setVisible] = useState(false);

  const content = [
    {
      question: "How do I purchase $Cloudax? ",
      answer:
        "$CLDX will be available for sale through our launchpad presale, or exchanges when it launches. How ever, if you are interested in buying $CLDX earlier at a discount, please contact manager@cloudax.io",
    },
    {
      question: "How many sales rounds does $Cloudax have?",
      answer: "Seed and public sale",
    },
    // {
    //   question: "How do I apply to the private sale whitelist? ",
    //   answer: "By completing the whitelist application via this form",
    // },
    {
      question: "When is the $Cloudax token launch?",
      answer:
        "Join our community or subscribe to our email notifications.",
    },
    {
      question: "I am new to crypto, How do I get started?",
      answer:
        "Our help center contains plenty of resources to get you started.",
    },
  ];

  const [select, setSelect] = useState("");

  const selector = (index) => {
    if (select === index) {
      setSelect("");
    } else {
      setSelect(index);
    }
  };

  return (
    <div id="faq" className="bg-black w-full py-1 text-white">
      <div className="my-[7rem]  text-center">
        <p className="text-[#F7B34C]">- Contact us -</p>
        <div className="w-11/12 mx-auto md:justify-center gap-4 md:gap-[2rem] flex flex-wrap mt-8">
          <div className="block text-center md:text-left md:flex w-full  md:w-fit gap-2 items-center mb-4">
            <DocumentText1 className="p-[.5rem] mb-2 md:mb-0 mx-auto md:mx-0 border border-[gray] rounded-lg" size="32" color="gray" />
            <p className="">
              <span className="block text-[gray]">Registration Number</span>
              <span>C443059</span>
            </p>
          </div>

          <div className="block text-center md:text-left md:flex w-full  md:w-fit gap-2 items-center mb-4">
            <DocumentText1 className="p-[.5rem] mb-2 md:mb-0 mx-auto md:mx-0 border border-[gray] rounded-lg" size="32" color="gray" />
            <p className="">
              <span className="block text-[gray]">Type</span>
              <span>Limited Company</span>
            </p>
          </div>

          <div className="block text-center md:text-left md:flex w-full  md:w-fit gap-2 items-center mb-4">
            <DocumentText1 className="p-[.5rem] mb-2 md:mb-0 border mx-auto md:mx-0 border-[gray] rounded-lg" size="32" color="gray" />
            <p className="">
              <span className="block text-[gray]">Country</span>
              <span>Cyprus</span>
            </p>
          </div>
        </div>

        <p className="my-8">CLOUDAX HOLDINGS LTD is a Limited Company registered in Cyprus. </p>

        <p className="text-[gray]">Registered address </p>
        <p>Dimofontos 7Z, 1075 Nicosia, Cyprus.</p>
      </div>


      <div className="w-11/12 mx-auto md:pl-5 flex my-[6rem] flex-wrap ">
        <div className="md:w-2/4 w-full mb-8">
          <h4 className=" font-semibold  text-[1.8rem] md:text-[2rem] landing lg:text-[4rem]">
            {" "}
            FAQ
          </h4>
          <p className="my-6 md:w-3/4 w-full  text-lg lg:text-xl">
            Didn't find the answers you are looking for? Explore our help center
          </p>
          <a
            href="http://help.cloudax.io/"
            target="_"
            className="py-4 block w-fit px-8 border border-[#ffffff74] rounded-3xl"
          >
            Help Center
          </a>
        </div>
        <div className="w-full md:w-[45%] mx-auto">
          {content.map(
            ({ question, answer }, index) =>
              index < 5 && (
                <div
                  className={`h-fit ${select === index ? "border-[orange]" : "border-[#ffffff46] "
                    } border-t w-full `}
                  key={index}
                >
                  <button
                    onClick={() => selector(index)}
                    className={`py-5 text-lg flex items-center justify-between w-full lg:text-xl ${select === index && "text-[orange]"
                      } `}
                  >
                    {" "}
                    <span className="w-[80%] text-left">{question}</span>
                    <FontAwesomeIcon
                      icon={select === index ? faMinus : faPlus}
                    />
                  </button>
                  {select === index && (
                    <div>
                      <p className="text-[gray] mb-4">{answer}</p>
                    </div>
                  )}
                </div>
              )
          )}
          {visible ? (
            <div>
              {content.map(
                ({ question, answer }, index) =>
                  index > 4 && (
                    <div
                      className={`h-fit ${select === index
                        ? "border-[orange]"
                        : "border-[#ffffff46] "
                        } border-t w-full `}
                      key={index}
                    >
                      <button
                        onClick={() => selector(index)}
                        className={`py-5 text-lg w-full flex justify-between items-center lg:text-xl ${select === index && "text-[orange]"
                          } `}
                      >
                        <span className="w-[80%] text-left">{question}</span>
                        <FontAwesomeIcon
                          icon={select === index ? faMinus : faPlus}
                        />
                      </button>
                      {select === index && (
                        <div>
                          <p className="text-[gray] mb-4">{answer}</p>
                        </div>
                      )}
                    </div>
                  )
              )}
              <button
                className="py-5 text-lg lg:text-xl border-t text-left flex items-center w-full border-[#ffffff42] "
                onClick={() => setVisible(false)}
              >
                {" "}
                See less
                <FontAwesomeIcon className="ml-3" icon={faAngleUp} />
              </button>
            </div>
          ) : (
            <></>
            // <button
            //   className="py-5 text-lg lg:text-xl border-t text-left flex items-center w-full border-[#ffffff42] "
            //   onClick={() => setVisible(true)}
            // >
            //   {" "}
            //   <span> Read more</span>
            //   <FontAwesomeIcon className="ml-3" icon={faAngleDown} />
            // </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faq;
