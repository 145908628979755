

const Element = ({ content, tag, tag1 }) => {
    if (tag == 'public') {
        return (
            <div className=" hidden lg:flex w-fit items-center gap-1 h-4/5 ">
                <p className=" leading-none text-[14px] text-right uppercase pl-2">
                    <span className="block ">{tag}</span>
                    <span className="block whitespace-nowrap ">{tag1}</span>
                </p>
                <p className="text-xl sm:text-xl px-3 whitespace-nowrap lg:text-4xl font-semibold w-3/4">{content}</p>
            </div>
        )
    } else {
        return (
            <div className="flex w-fit items-center gap-1 h-4/5 border-r border-[#ffffff6a]">
                <p className=" leading-none text-[14px] text-right uppercase pl-2">
                    <span className="block ">{tag}</span>
                    <span className="block  whitespace-nowrap">{tag1}</span>
                </p>
                <p className="text-xl sm:text-2xl px-3 whitespace-nowrap lg:text-4xl font-semibold w-3/4">{content}</p>
            </div>
        )
    }
}

export default Element