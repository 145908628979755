import { motion } from "framer-motion";
import Tabs from "../small/Tabs";
import Charts from "../small/Charts";

const Tokennomics = () => {
  return (
    <section
      id="token"
      className="py-1 bg-cover-pic bg-cover bg-center text-white"
    >
      <section className=" w-11/12 mx-auto my-[8rem]">
        <div className="lg:pl-12 md:w-11/12 mx-auto md:text-center my-6 lg:mt-10 lg:mb-[7rem]">
          <h3 className="text-[1.8rem] md:text-[2rem] landing lg:text-[4rem] leading-none mb-5 font-semibold">
            Tokenomics
          </h3>
          <p className="mx-auto lg:leading-tight w-full lg:w-3/4 text-[#ffffffbd] lg:text-2xl">
            Cloudax (CLDX) is the native token of the Cloudax blockchain. There
            would be a private and public sale on cloudax launchpad.
          </p>
        </div>
        <div className=" flex flex-col md:flex-row gap-y-10 justify-between lg:items-center h-fit w-full lg:px-[3rem]">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: "easeOut", duration: 1 }}
            className=" w-full md:w-2/4 lg:w-[60%]"
          >
            <div className="flex">
              <Tabs h1="$0.05" p1="Public Sale Price" />
              <Tabs h1="$630,000" p1="Initial Market Cap" />
            </div>
            <div className="flex ">
              <Tabs h1="200 million" p1="Total Supply" />
              <Tabs h1="12.6 Million" p1="Initial Circulating Supply" />
            </div>

            <div className="mt-8 lg:mt-12 w-11/12 text-[#ffffffbd]">
              <div className="flex justify-between ">
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#FEC64D] rounded-xl"></div>
                  <p className=" whitespace-nowrap lg:text-2xl">Ecosystem</p>
                </div>
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#DBE3F1] rounded-xl"></div>
                  <p className="lg:text-2xl">Public IDO</p>
                </div>
                {/* <div className='flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]'>
                                    <div className='w-[3rem] h-[1rem] bg-[#FFEFCA] rounded-xl'></div>
                                    <p className='whitespace-nowrap lg:text-2xl'>Marketing</p>
                                </div> */}
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#74CFFD] rounded-xl"></div>
                  <p className="whitespace-nowrap lg:text-2xl">Treasury</p>
                </div>
              </div>
              <div className="flex my-3 md:my-6 justify-between">
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#86C18A] rounded-xl"></div>
                  <p className="lg:text-2xl">Liquidity</p>
                </div>
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#FC9265] rounded-xl"></div>
                  <p className="lg:text-2xl">Team</p>
                </div>
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#D3B683] rounded-xl"></div>
                  <p className="lg:text-2xl">Advisors</p>
                </div>
              </div>
              <div className="flex gap-[2rem] ">
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#4A59EE] rounded-xl"></div>
                  <p className="lg:text-2xl">Seed Sale</p>
                </div>
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#353F4F] rounded-xl"></div>
                  <p className="lg:text-2xl">Staking</p>
                </div>
                <div className="flex gap-1 lg:gap-3 lg:items-center flex-col lg:flex-row  w-[30%]">
                  <div className="w-[3rem] h-[1rem] bg-[#FF64C1] rounded-xl"></div>
                  <p className="lg:text-2xl">Marketing</p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: "easeOut", duration: 1 }}
            className=" w-full md:w-[20rem] lg:w-[24rem]"
          >
            <Charts />
          </motion.div>
        </div>
      </section>
    </section>
  );
};

export default Tokennomics;
