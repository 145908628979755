import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';

const data = {
    labels: ['Seed Sale', 'Public IDO', 'Ecosystem', 'Staking', 'Marketing', 'Treasury', 'Liqyuduty', 'Team', 'Advisor'],
    datasets: [{
        label: 'Token Distribution',
        data: [5, 20, 10, 10, 10, 20, 10, 10, 5],
        backgroundColor: ['#4A59EE', '#DBE3F1', '#FEC64D', '#353F4F', '#FF64C1', '#74CFFD', '#86C18A', '#FC9265', '#D3B683'],
        borderWidth: 0,
        borderColor: '#3DAEFA',
    }],
}

const options = {
    color: 'black',
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "bottom",
        },
    },
}


const Charts = () => {
    return (
        <Doughnut data={data} options={options} />
    )
}

export default Charts