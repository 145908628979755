import Icons from "../small/icons";
import Element from "../small/Element";
import { motion } from "framer-motion";
import {
  faInstagram,
  faTwitter,
  faGithub,
  faYoutube,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";

const Section1 = () => {
  return (
    <div
      id="about"
      className="bg-cover bg-frame bg-center text-white w-full py-1 "
    >
      <div className=" h-fit mt-[-5px] py-1 w-full text-white">
        <div className="w-11/12 mx-auto h-full md:flex justify-between items-center my-3">
          <div className=" hidden  md:flex gap-1 h-full w-fit items-center">
            <Element tag="total" tag1="supply" content="200 million" />
            <Element tag="initial" tag1="market cap" content="$630,000" />
            <Element tag="public" tag1="sale price" content="$0.05" />
          </div>
          <motion.div
            initial={{ x: 70 }}
            whileInView={{ x: 0 }}
            transition={{ ease: "easeOut", duration: 1 }}
            className="flex justify-center mx-auto md:mx-0 sm:gap-1  gap-5"
          >
            <Icons icons={faTelegram} link="https://t.me/cloudaxofficial" />
            <Icons icons={faTwitter} link="https://twitter.com/CloudaxHQ" />
            <Icons icons={faInstagram} />
            <Icons icons={faGithub} />
            <Icons icons={faYoutube} />
          </motion.div>
        </div>
      </div>

      <div className="flex gap-5 lg:gap-10 w-11/12 mx-auto justify-between flex-wrap mb-[6rem] mt-[4rem] md:my-[9rem]">
        <div className=" rounded-[3rem] border border-[#ffffff28] py-[4rem] px-[2rem] w-full md:w-[48%]">
          <h2 className="font-extrabold text-transparent text-[1.8rem] md:text-[2.2rem] lg:text-[3rem] bg-clip-text bg-gradient-to-b from-[#474FF4] via-[#56C2FF] to-[#474FF4]">
            For Creators
          </h2>
          <p className="lg:text-2xl mt-[1rem] mb-[3rem] text-[#ffffff89]">
            Cloudax provides a platform for wholesome content creators to
            monetize and earn rewards on their contents forever in crypto. Our
            powerful tools helps anyone sign their content to blockchain, making
            it piracy proof on our platform, while granting them full ownership
            and freedom.{" "}
          </p>
          <a
            href="https://spotlight.cloudax.io"
            target="blank"
            className="font-extrabold      hover:border-[white] bg-clip-text bg-gradient-to-b border-[#56C2FF] from-[#474FF4] to-[#56C2FF] text-transparent py-3 px-6 border rounded-3xl mb-5  block w-fit"
          >
            Read More
          </a>
        </div>
        <div className="text-center bg-center rounded-[3rem] w-full md:w-[48%] bg-barca bg-cover py-[4rem] px-[2rem]">
          <h1 className="text-[1.8rem] md:text-[2.3rem] font-extrabold lg:text-[3rem] ">
            For Communities
          </h1>
          <p className="lg:text-2xl mt-[1rem] mb-[3rem]">
            Cloudax is built to offer great financial rewards to its
            ever-expanding community through its smart tokenomics design. By
            consuming, engaging and building valuable connections, participants
            can accrue great rewards in crypto and NFTs.{" "}
          </p>
          <a
            href="https://spotlight.cloudax.io"
            target="blank"
            className="hover:border-2 py-3 px-6 border rounded-3xl mx-auto  block w-fit"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Section1;
