const SmallBox = ({ h1, p1, row }) => {
  return (
    <div
      className={`w-11/12 hidden md:block sm:w-[22rem] lg:w-[28rem] ${
        row ? "text-left" : "text-right"
      }`}
    >
      <h3 className=" text-xl lg:text-4xl font-semibold capitalize ">{h1}</h3>
      <p className=" capitalize">{p1}</p>
    </div>
  );
};

export default SmallBox;
