import wallet from "../../../images/icon-images/wallet.png";
import cloudshopa from "../../../images/icon-images/cloudshopa.png";
import p2p from "../../../images/icon-images/p2p.png";
import cloudfi from "../../../images/icon-images/cloudFi.png";
import launchpad from "../../../images/icon-images/launchpad.png";
import nft from "../../../images/icon-images/nft.png";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Grid from "../small/Grid";
import "swiper/css";

const Grids = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const info = [
    {
      name: "CloudP2P",
      image: p2p,
      text: " Our highly efficient peer-to-peer service with multi-currency support, cross-chain support, and hardware  ",
      extraText:
        "wallets. Exchange your fiat for crypto, trade between your favorite crypto exchanges, facilitate trades, and guarantee safety of your funds with private encrypted wallets. Get rewarded for positive trading history with CloudP2P. Traders can rate each other based on transactions and general behavior. Choose who to trade with and develop relationships. Our dispute resolution services are tailored towards fair and beneficial settlements for all parties.",
      checks: [
        "Multi-faceted currency/exchange support",
        "Cold storage, encrypted wallets",
        "Reputation system",
        "Dispute resolution service",
      ],
      tag: "Download now",
      link: "https://onelink.to/vcz9k5",
    },
    {
      name: "Cloudshopa",
      image: cloudshopa,
      text: "We provide the ultimate discount service for all your favorite shopping platforms.  Enjoy discounts and",
      extraText:
        " purchase gift cards from your favourite stores, service providers and even artistes…Cloudshopa is your go-to discount ecommerce platform.",
      checks: [
        "Preferred discount percentage purchase",
        "Shop, trade, and sell discounts",
        "Multi-platform application",
      ],
      tag: "Visit page",
      link: "https://www.cloudshopa.com/",
    },
    {
      name: "CloudFi",
      image: cloudfi,
      text: " Our all-inclusive social media ecosystem is dedicated to providing authors, communities, and individuals with",
      extraText:
        "an interactive, wholesome and faith-based content platform. CloudFi is the only blockchain powered network that rewards content creators and consumers alike in crypto. ",
      checks: [
        "Piracy proof features",
        "Crypto payout for creators and consumers",
        "Smart tokenomics design",
      ],
      tag: "Coming soon",
      link: "/",
    },
    {
      name: "CloudWallet",
      image: wallet,
      text: " Our highly secure custodial wallet developed to support cross-platform integration with wholesome and ",
      extraText:
        "faith-based community platforms, giving them an opportunity to leverage our technology to reward their subscribers whilst monetizing their content.",
      checks: [
        "Custodial Wallet for Cloudax Lite",
        "Web and Mobile Application",
        "Seamless Integration to 3rd Party Platforms",
      ],
      tag: "Coming soon",
      link: "/",
    },
    {
      name: "Launchpad",
      image: launchpad,
      text: "Our top tier incubator for quality decentralized projects which promote, publish or offer faith based and/or ",
      extraText:
        "wholesome utility. Stake Cloudax tokens to enjoy the best crypto presales.",
      checks: [],
      tag: "Coming soon",
      link: '/'
      // link: "https://t.me/cloudaxofficial",
    },
    {
      name: "NFT Marketplace",
      image: nft,
      text: " We offer a sophisticated yet user-friendly exchange within the Cloudax ecosystem where users can trade ",
      extraText: "cryptocurrencies, earn interest and transact.",
      checks: [],
      tag: "Coming soon",
      link: "/",
    },
  ];

  return (
    <div id="cloudax_ecosystem" className="bg-paul bg-cover w-full p-1">
      <div className="w-11/12 mx-auto mt-[5rem] md:mt-[7rem] mb-[5rem]">
        <h3 className=" landing mb-0  text-[1.8rem] md:text-[2.5rem] lg:text-[4rem] leading-tight">
          Cloudax Ecosystem
        </h3>
        <p className=" lg:text-2xl">
          Cloudax is an ever-expanding and scalable decentralised ecosystem.
        </p>
      </div>

      <div
        className={`w-11/12 mx-auto justify-between overflow-scroll hidden md:flex myScroll flex-nowrap  gap-[1rem]  mb-[7rem] gap-y-[2rem]`}
      >
        {info.map((item, index) => (
          <Grid item={item} index={index} />
        ))}
      </div>
      <div className=" flex  md:hidden">
        <Swiper
          className="mySwiper"
          onSlideChange={(e) => {
            setActiveSlide(e.activeIndex);
          }}
        >
          {info.map((item, index) => (
            <SwiperSlide key={index}>
              <Grid item={item} index={index} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="flex md:hidden justify-center my-16">
        <div className="h-1 bg-white w-[200px] rounded-full overflow-hidden">
          <div
            style={{ width: `${((activeSlide + 1) / 4) * 100}%` }}
            className="h-full bg-black duration-300"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Grids;
