import Layout from "./layout/Layout";
import Landing from "./components/app/big/Landing";
import Team from "./pages/Team";
// import News from "./components/app/big/News";
// import Tree from "./components/app/big/Tree";
import Tokennomics from "./components/app/big/Tokennomics";
import Faq from "./pages/Faq";
// import ChainWallet from "./components/app/big/ChainWallet";
import Inbox from "./components/app/big/Inbox";
import Section1 from "./components/app/big/Section1";
import Road from "./components/app/big/Road";
// import MrCyrill from "./components/app/big/MrCyrill";
import Grids from "./components/app/big/Grids";
import Tree from "./components/app/big/Tree";

const data = [
  {
    link: "https://captainaltcoin.com/cloudax-io-launches-groundbreaking-platform-redefining-content-monetization-in-the-web3-era/",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ5j0LqXwQ1YInDG4hTpCc87_NpykgL38ssIn9cv-Gug&s",
  },
  {
    link: "https://msmart-media.com/news/cloudaxweb3-company-bridging-content-access-monetization",
    image:
      "https://msmart-media.com/static/media/LOGO.d3043420ad24f8ab6d0b.png",
  },
  {
    link: "https://cryptopotato.com/cloudax-io-launches-groundbreaking-platform-redefining-content-monetization-in-the-web3-era/",
    image: "https://cryptopotato.com/wp-content/uploads/2022/06/cplogo3.png",
  },
  {
    link: "https://www.bitcoininsider.org/article/241258/cloudaxio-launches-groundbreaking-platform-redefining-content-monetization-web3",
    image:
      "https://newline.tech/wp-content/uploads/2023/02/2ca67607adf60a281e59a94ab70e37ba.jpeg",
  },
  {
    link: "https://thebitcoinnews.com/cloudax-io-launches-groundbreaking-platform-redefining-content-monetization-in-the-web3-era/",
    image:
      "https://thebitcoinnews.com/wp-content/uploads/2018/03/TheBitcoinNews-1.png",
  },
  {
    link: "https://coinmarketcap.com/community/articles/65ce26ce6596080e8fbb7760/",
    image:
      "https://seeklogo.com/images/C/coinmarketcap-logo-064D167A0E-seeklogo.com.png",
  },
];

function App() {
  return (
    <Layout>
      <Landing />
      {/* <ChainWallet /> */}
      <Section1 />
      <Grids />
      <div className="p-1 text-white bg-[#03036c] bg-central bg-center bg-cover">
        <h1 className="text-[1.8rem] md:text-[2rem] text-center mt-[5rem] md:mt-[8rem] lg:text-[4rem]  landing font-bold">
          We Power
        </h1>
        <p className="mb-6  lg:w-3/4 mx-auto lg:text-2xl text-center font-light">
          {" "}
          These Companies are happily powered by us
        </p>
        <div className="flex items-center justify-center gap-6 mb-[8rem] mt-[4rem] md:mb-[8rem]">
          <img
            className="w-[7rem] rounded-full"
            src={require("./images/partners/loveworld.jpeg")}
            alt="love"
          />
          <img
            className="w-[8rem]"
            src={require("./images/partners/network1.jpeg")}
            alt="network"
          />
        </div>

        <h1 className="text-[1.8rem] md:text-[2rem] text-center mt-[5rem] md:mt-[8rem] lg:text-[4rem]  landing font-bold">
          Featured In
        </h1>
        <div className="flex items-center gap-6 mb-[5rem] mt-[4rem] md:mb-[8rem] flex-wrap w-full justify-center">
          {data.map((item, index) => (
            <a rel="noreferrer" href={item?.link} key={index} target="_blank">
              <img className="w-[8rem]" src={item?.image} alt="network" />
            </a>
          ))}
        </div>
      </div>
      {/* <News /> */}
      <Tokennomics />
      {/* <Road /> */}
      <Tree />
      <Team />
      <Inbox />
      <Faq />
    </Layout>
  );
}

export default App;
