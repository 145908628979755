import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Grid = ({ item, index }) => {
  const { name, extraText, text, checks, tag, link, image } = item;
  const [hover, setHover] = useState(0);
  const [section, setSection] = useState("");

  const dialogue = (data) => {
    if (section) {
      setSection("");
      return;
    }
    setSection(data);
  };

  return (
    <div
      onMouseEnter={() => setHover(index + 1)}
      onMouseLeave={() => setHover(0)}
      className={`bg-white shrink-0 p-[2rem] ${section === name
        ? "w-full md:w-full xl:w-[65%]"
        : "w-full md:w-[46%] lg:w-[45%] xl:w-[31%] "
        } rounded-[3rem] transition-width ease-linear duration-300 hover:bg-barca bg-center  bg-cover hover:text-white `}
    >
      <img
        className={`mb-[1rem] w-full lg:w-[22.5rem] grayscale-0
                 ${hover === index + 1 ? "md:grayscale-0" : "md:grayscale"}`}
        src={image}
        alt="wallet"
      />
      <div className=" mb-8">
        <p className="md:text-[2rem] lg:text-[2.5rem] text-[1.5rem] font-bold lg:font-semibold whitespace-nowrap">
          {" "}
          {name}{" "}
        </p>
        <p className="lg:text-xl">
          {text}
          {section === name && <span>{extraText}</span>}
          <span
            onClick={() => dialogue(name)}
            className={`cursor-pointer font-extrabold`}
          >
            {section === name ? " ...Read less" : " ...Read more"}
          </span>
        </p>
        {section === name && (
          <div className="mt-4">
            {checks.length > 0 &&
              checks.map((check, i) => (
                <p className="lg:text-xl mb-1" key={i}>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {check}
                </p>
              ))}
          </div>
        )}

        <a
          target="blank"
          className="mt-6 flex items-center w-full md:w-[22rem] rounded-xl py-5 px-4  border-2 "
          href={link}
        >
          <span className="lg:text-lg"> {tag}</span>
          <FontAwesomeIcon className="ml-6" icon={faArrowRight} />
        </a>
      </div>
    </div>
  );
};

export default Grid;
