import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Box = ({ icon, h1, l1, p1, h2 }) => {
  return (
    <div className=" bg-[#1b1b2c] border-t border-l p-[1rem] md:p-[2rem] border-[#ffffff51] w-11/12 sm:w-[22rem] rounded-[2rem] lg:w-[28rem]">
      <FontAwesomeIcon
        className="text-3xl hidden md:block text-[#3DAEFA]"
        icon={icon}
      />
      <h3 className=" text-xl lg:text-2xl font-semibold mt-4 lg:mt-6 leading-none ">
        {h1}
      </h3>
      <h4 className="lg:text-md mb-4 lg:mb-6 leading-none ">{h2}</h4>
      <p className="text-[#ffffffb6] lg:text-md">{p1}</p>
      <ul className="list-disc ml-5 text-[#ffffffb6]">
        {l1 &&
          l1.map((item, index) => (
            <li className="lg:text-lg" key={index}>
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Box;
