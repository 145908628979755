import mobile from "../../../images/video/nobile.MP4";
import video from "../../../images/video/CLOUDAX WEBSITE HEADER style 01 (1).mp4";

const Landing = () => {
  return (
    <section
      id="home"
      className=" h-screen bg-[#03036c] bg-central bg-center bg-cover relative flex items-end"
    >
      <video
        className="hidden md:block absolute h-screen brightness-75"
        autoPlay={true}
        controls={false}
        loop={true}
        playsInline
        muted
        width="100%"
        height="100%"
      >
        <source src={video} type="video/mp4" />
      </video>

      <video
        className=" md:hidden absolute h-screen brightness-75"
        autoPlay={true}
        controls={false}
        loop={true}
        playsInline
        muted
        width="100%"
        height="100%"
      >
        <source src={mobile} type="video/mp4" />
      </video>

      <div className="  text-white z-[2] mb-[5rem]  w-full mt-[-3rem]">
        <h1 className="dynamic-text-shadow text-[1.8rem] w-11/12 mx-auto leading-none md:text-[2.5rem] lg:text-[4rem] 3x font-semibold landing ">
          <span className="block"> The First </span>
          <span className="block">
            Blockchain Network
            <br />
            For Wholesome Content.
          </span>
        </h1>
        <p className=" w-11/12 mx-auto leading-relaxed  font-normal md:leading-loose break-words sm:leading-loose lg:leading-7 myFont mb-4 mt-2 md:mt-4 lg:mt-6 lg:mb-10 lg:text-2xl">
          Cloudax is a Web3 paradise for wholesome content creators
          <span className="md:block md:mt-2">
            and communities; rewarding them with crypto
          </span>
        </p>
        <div className="mx-auto w-11/12  flex flex-col md:flex-row gap-3 md:gap-6">
          <a
            target="blank"
            href="/"
            // href="https://cloudpad.cloudax.io/"
            className=" flex items-center justify-center transition-all duration-500 ease-in-out font-bold h-[68px] md:h-[50px] w-full hover:bg-transparent hover:text-[white] hover:border md:w-[160px] rounded-2xl md:rounded-3xl bg-[white] text-[#4C53F7] "
          >
            Join Presale
          </a>
          <a
            target="blank"
            href="http://cloudaxhq.gitbook.io/"
            className="transition-all duration-500 ease-in-out flex items-center justify-center border-[2px] border-[#ffffff2f] h-[68px] md:h-[50px] w:[193px] md:w-[160px] font-bold rounded-3xl hover:border-[white] "
          >
            Whitepaper
          </a>
        </div>
      </div>
    </section>
  );
};

export default Landing;
