import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import SmallBox from "./SmallBox";
import Box from "./Box";
import { motion } from "framer-motion";

const Direction = ({ row, icon, y1, z1, l1, h1, p1, h2 }) => {
  if (row) {
    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1 }}
          className="hidden  md:flex gap-10 items-center justify-center mx-auto my-[-1px] h-[19rem]"
        >
          <Box h2={h2} p1={p1} icon={icon} h1={h1} l1={l1} />
          <div className="bg-[#3DAEFA] w-[1rem] h-full flex items-center">
            <FontAwesomeIcon
              className="text-[#3DAEFA] text-7xl ml-[-1rem]"
              icon={faCaretLeft}
            />
          </div>
          <SmallBox h1={y1} p1={z1} row={row} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1 }}
          className=" flex md:hidden gap-7 items-center w-[90%] mx-auto "
        >
          <div className="bg-[#3DAEFA] w-[1rem] h-[19rem] flex items-center ">
            <FontAwesomeIcon
              className="text-[#3DAEFA] text-7xl mr-[-1rem]"
              icon={faCaretRight}
            />
          </div>
          <div className="flex flex-col gap-y-7 w-full">
            <SmallBox h1={y1} p1={z1} row={true} />
            <Box icon={icon} h1={h1} l1={l1} />
          </div>
        </motion.div>
      </>
    );
  } else {
    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1 }}
          className="hidden  md:flex gap-10 items-center justify-center mx-auto my-[-1px] h-[19rem] "
        >
          <SmallBox h1={y1} p1={z1} row={row} />
          <div className="bg-[#1b1b2c] w-[1rem] h-full flex items-center">
            <FontAwesomeIcon
              className="text-[#1b1b2c] text-7xl mr-[-1rem]"
              icon={faCaretRight}
            />
          </div>
          <Box icon={icon} h1={h1} l1={l1} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1 }}
          className=" flex md:hidden gap-7 items-center  w-[90%] mx-auto"
        >
          <div className="bg-[#1b1b2c] w-[1rem] h-[17rem] flex items-center">
            <FontAwesomeIcon
              className="text-[#1b1b2c] text-7xl mr-[-1rem]"
              icon={faCaretRight}
            />
          </div>
          <div className="flex flex-col gap-y-7 w-full">
            <SmallBox h1={y1} p1={z1} row={true} />
            <Box icon={icon} h1={h1} l1={l1} />
          </div>
        </motion.div>
      </>
    );
  }
};

export default Direction;
