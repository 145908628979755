

const Tabs = ({ h1, p1 }) => {
    return (
        <div className="w-full py-[8px]">
            <h4 className="text-lg md:text-xl landing lg:text-[3rem] lg:my-6">{h1}</h4>
            <p className="text-[#ffffffbd] lg:text-2xl">{p1}</p>
        </div>
    )
}

export default Tabs