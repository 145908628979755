import {
  faLightbulb,
  faRadio,
  faRocket,
  faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import Direction from "../small/Direction";
import { Cloud } from "iconsax-react";

const Tree = () => {
  return (
    <section
      id="tree"
      className=" bg-[#03035b] bg-pad w-full bg-contain text-white py-2"
    >
      <p className=" mt-[6rem] text-center uppercase lg:text-xl font-extrabold">
        Building The Future of Cloudax
      </p>

      <h1 className=" leading-none text-center font-semibold my-6 text-[1.8rem] md:text-[2.5rem] lg:text-[4rem] landing">
        The Roadmap
      </h1>

      <p className="w-11/12 md:w-2/4 lg:w-[55%] lg:leading-normal mx-auto lg:text-xl text-center text-[#ffffffa6]">
        Cloudax is a Web3 paradise for wholesome content creators and
        communities; rewarding them with crypto
      </p>

      <p className="w-[5rem] h-[5rem] sm:w-[8rem] sm:h-[8rem] flex items-center justify-center mx-auto my-10 rounded-full bg-[#ffffff0f]  shadow-md shadow-[#ffffff57] font-semibold lg:text-[1.5rem] leading-none">
        <Cloud size={25} />
      </p>

      <div className="mb-[7rem]">
        <Direction
          icon={faLightbulb}
          row={true}
          h1="Oct - Dec 2023"
          l1={["CloudP2P soft launch ", "Cloudshopa soft launch"]}
        />

        <Direction
          icon={faPeopleArrows}
          row={false}
          h1="Jan - June 2024"
          l1={[
            "Alpha / Beta testing - Cloudwallet",
            "Upgrade  of CloudP2P",
            "Vendor Onboarding / Product development of Cloudshopa",
          ]}
        />

        <Direction
          icon={faRadio}
          row={true}
          h1="July - Sept 2024"
          l1={[
            "Launch of Upgraded CloudP2P",
            "Token Presale and Launch",
            "Exchange and DEX Listing",
            "Launch of Cloudwallet",
            "Community Outreach",
          ]}
        />

        <Direction
          icon={faRocket}
          row={false}
          h1="Oct - Dec 2024"
          l1={[
            "Integration of CloudWallet with First Subscriber Partner Platforms",
            "CloudP2P First 100,000 Users Milestone",
            "Cloudshopa Merch Store Launch",
            "Community Outreach ",
          ]}
        />

        <Direction
          icon={faRadio}
          row={true}
          h1="Jan - Mar 2025"
          l1={[
            "New Integrations with CloudWallet (10)",
            "CloudP2P 250,000 Users Milestone",
            "CloudPad Launch",
            "Complete CloudFi MVP",
            "Token Event",
          ]}
        />

        <Direction
          icon={faRocket}
          row={false}
          h1="April - June 2025"
          l1={[
            "New Integrations with CloudWallet (30)",
            "CloudP2P 500,000 Users Milestone",
            "CloudPad Launch (First 2 projects)",
            "Alpha/Beta Testing of CloudFi",
            "Token Event",
          ]}
        />

        <Direction
          icon={faRadio}
          row={true}
          h1="July - Sept 2025"
          l1={[
            "CloudFi Launch",
            "Expansion of Existing Projects",
            "Token Event",
          ]}
        />
      </div>
    </section>
  );
};

export default Tree;
