

const Disclaimer = () => {
  return (
    <div>
        <h1> <div>No content yet check back later</div></h1>
    </div>
  )
}

export default Disclaimer