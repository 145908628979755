import { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const Team = () => {
  const [num, setNum] = useState('');
  const people = useRef([])
  const myRef = useRef()

  useEffect(() => {
    const options = {
      threshold: 0.8,
      root: myRef.current
    }
    const observer = new IntersectionObserver((entries) => {
      const enter = entries[0].target.id
      enter && setNum(enter)
    }, options)

    people.current.forEach(item => observer.observe(item))
  }, []);

  const nav = [0, 1, 2, 3, 4]

  const teams = [
    {
      img: require("../images/teams/deacon.jpeg"),
      name: "Olu Ayanfalu",
      title: "Co-founder CEO",
      twitter: '',
      linkedn: 'https://www.linkedin.com/in/olu-a-29724bb9',
    },
    {
      img: require("../images/teams/flip.jpg"),
      name: "Filip Ocenas",
      title: "Co-founder",
      twitter: '',
      linkedn: 'http://www.linkedin.com/in/filip-ocenas-70377a292',
    },
    {
      img: require("../images/teams/lara.jpg"),
      name: "Omolara Popoola",
      title: "Head of Operations",
      twitter: '',
      linkedn: 'https://www.linkedin.com/in/omolara-popoola-63682063/',
    },
    {
      img: require("../images/teams/damola.jpeg"),
      name: "Adedamola Daniel",
      title: "Head of Technical",
      twitter: '',
      linkedn: 'https://www.linkedin.com/in/adedamola-ephraim/',
    },

    {
      img: require("../images/teams/nasa.jpeg"),
      name: "Chinaza O.H.",
      title: "Blockchain Lead",
      twitter: '',
      linkedn: 'https://www.linkedin.com/in/nvzvhenry/',
    },
    {
      img: require("../images/teams/justice.jpg"),
      name: "Justice Osawe",
      title: "Head of Product",
      twitter: '',
      linkedn: 'https://www.linkedin.com/in/justice-osawe',
    },
    {
      img: require("../images/teams/software.jpeg"),
      name: "Adalton Reis ",
      title: "Senior  Software Architect",
      twitter: '',
      linkedn: 'https://www.linkedin.com/in/adaltonreis/',
    },
    {
      img: require("../images/teams/charles.jpg"),
      name: "Dan-Charles Okoto",
      title: "Adviser",
      twitter: '',
      linkedn: 'https://www.linkedin.com/in/dancharlesokoto',
    },
  ];


  return (
    <div id='team' className=" py-1 w-full bg-team bg-cover bg-center text-white">
      <div className=" mx-auto w-11/12 text-center mt-20 md:pl-8 pb-1">
        <p className='text-[orange] mb-[2.5rem] mt-[8rem]'></p>
        <h1 className="text-[1.8rem] md:text-[2rem] lg:text-[4rem] leading-none my-5 landing font-bold">Our Core Team</h1>
        <p className="mt-6 lg:mt-10 lg:w-3/4 mx-auto lg:text-2xl"> Cloudax is led by highly motivated experts in blockchain technology, business, economics, software engineering, etc.
        </p>
      </div>

      <div ref={myRef} className=" mt-[5rem] mb-[7rem] z-40 flex overflow-x-auto md:justify-center snap-mandatory scrollbar-hide transition-all duration-500 md:flex-wrap snap-x  mx-auto w-11/12 gap-y-10 gap-[4rem]">
        {teams.map(({ img, name, title, twitter, linkedn }, index) =>
          <div id={index}
            ref={(element) => people.current[index] = element}
            className="w-full  snap-end md:w-[25%] lg:w-[20%] xl:w-[15%] flex-shrink-0 md:py-6 " key={index}>
            <img
              className={`w-[13rem] ${index === 0 ? 'object-bottom object-cover' : 'object-left-top object-cover '} rounded-full bg-white h-[13rem] mx-auto`}
              src={img}
              alt={name}
            />
            <div className=" mb-5 text-center">
              <p className="font-semibold mt-3 lg:text-xl lg:mb-3 lg:mt-4">{name}</p>
              <p className='lg:text-xl capitalize text-[#ffffffcc]'>{title}</p>
              <div className='flex justify-center gap-3 text-base mt-2'>
                {twitter && <a href={twitter} target='blank'><FontAwesomeIcon icon={faTwitter} /> </a>}
                <a href={linkedn} target='blank'> <FontAwesomeIcon icon={faLinkedinIn} /></a>
              </div>
            </div>
          </div>)}
      </div>
      <div className='flex justify-center gap-1 w-full md:hidden mt-[-5rem] mb-[4rem]'>
        {nav.map((item, index) => <FontAwesomeIcon className={` ${num === index ? 'text-[blue] text-[12px]' : 'text-[gray] text-[10px]'}`} key={index} icon={faCircle} />)}
      </div>

    </div>
  );
};

export default Team;
