import logo from "../images/logo/cloudax1-_3_.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBars,
  faAngleUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import {
  faTwitter,
  faTelegram,
  faMedium,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import List from "../components/layout/List";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Layout = ({ children }) => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(true);
  const [side, setSide] = useState(false);
  const year = new Date().getFullYear();
  const location = useLocation().pathname;
  const navigation = useNavigate();

  const controlNavbar = () => {
    if (window.scrollY > 500) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  const navigateHome = () => {
    document.getElementById("home").scrollIntoView({ behavior: "smooth" });
  };

  const leaves = () => {
    if (menu) {
      setMenu(false);
    }
  };

  return (
    <section className="overflow-x-hidden">
      <nav
        id="nav"
        className={`transition-all ${show ? (location === "/" ? "bg-transparent" : "bg-black") : "bg-black"
          } ${show ? "absolute" : "fixed"
          } top-0 z-10 h-16 md:h-18 w-full text-white`}
      >
        <div className="flex justify-between w-11/12  mx-auto h-full items-center">
          <img
            onClick={navigateHome}
            className=" w-[8rem] "
            src={logo}
            alt="logo"
          />
          <ul className="hidden cursor-pointer lg:flex items-center w-auto h-full gap-8">
            <a
              href="/#about"
              className="h-full flex font-light items-center hover:border-b-2 hover:border-[white]"
            >
              About
            </a>
            <a
              href="/#cloudax_ecosystem"
              className="h-full font-[18px] flex items-center hover:border-b-2 hover:border-[white]"
            >
              {" "}
              Ecosystem
            </a>
            <a
              href="/#token"
              className="h-full flex font-light items-center hover:border-b-2 hover:border-[white]"
            >
              Token Info
            </a>
            <a
              href="/#team"
              className="h-full flex font-light items-center hover:border-b-2 hover:border-[white]"
            >
              Team
            </a>
            <a
              href="/#faq"
              className="h-full flex font-light items-center hover:border-b-2 hover:border-[white]"
            >
              {" "}
              FAQ
            </a>
            <div
              onMouseEnter={() => setMenu(!menu)}
              onMouseLeave={leaves}
              className="relative h-full transition-all"
            >
              <button
                onClick={() => setMenu(!menu)}
                className=" flex font-light items-center h-full"
              >
                Community{" "}
                <FontAwesomeIcon
                  className="ml-1"
                  icon={menu ? faAngleUp : faAngleDown}
                />{" "}
              </button>
              {menu && (
                <div
                  onMouseLeave={() => setMenu(false)}
                  className="py-2 absolute top-18 left-[-1.5rem] bg-black w-[10rem]"
                >
                  <a
                    className=" py-3 px-5 flex items-center gap-3 hover:bg-[#ffffff14] hover:text-[#2F79F9]"
                    target="blank"
                    href="https://t.me/cloudaxofficial"
                  >
                    <FontAwesomeIcon icon={faTelegram} /> Telegram
                  </a>
                  <a
                    className="py-3 px-5 flex items-center gap-3 hover:bg-[#ffffff14]  hover:text-[#2F79F9]"
                    target="blank"
                    href="https://twitter.com/CloudaxHQ"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faTwitter} /> Twitter
                  </a>
                  <a
                    className="py-3 px-5 flex items-center gap-3 hover:bg-[#ffffff14]  hover:text-[#2F79F9]"
                    target="blank"
                    href="https://github.com/cloudaxglobal"
                  >
                    <FontAwesomeIcon icon={faGithub} /> Github
                  </a>
                </div>
              )}
            </div>
          </ul>
          <button
            onClick={() => setSide(!side)}
            className={`flex h-full items-center just lg:hidden ${side ? "text-[2rem]" : "text-xl"
              }`}
          >
            {" "}
            <FontAwesomeIcon icon={side ? faXmark : faBars} />
          </button>
        </div>
        <AnimatePresence>
          {side && (
            <motion.div
              key="box"
              initial={{ x: 0 }}
              exit={{ x: 500 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
              className={`w-full animate-mover flex flex-col fixed h-screen  text-white lg:hidden bg-black `}
            >
              <a
                href="/#about"
                onClick={() => setSide(false)}
                className=" flex py-4 w-11/12 mx-auto  items-center border-b border-[#ffffff37]"
              >
                About
              </a>
              <a
                onClick={() => setSide(false)}
                href="/#token"
                className="w-11/12 mx-auto flex items-center py-4  border-b border-[#ffffff3d]"
              >
                Token Info
              </a>
              <a
                href="/#ecosystem"
                className=" w-11/12 mx-auto flex items-center py-4  border-b border-[#ffffff3d]"
              >
                {" "}
                Ecosystem
              </a>
              <a
                href="/#team"
                onClick={() => setSide(false)}
                className=" flex items-center py-4 w-11/12 mx-auto border-b border-[#ffffff3d]"
              >
                Team
              </a>
              <a
                href="/#faq"
                className=" w-11/12 mx-auto flex items-center py-4  border-b border-[#ffffff3d]"
              >
                {" "}
                FAQ
              </a>
              <div className="py-4 flex gap-5 text-xl h-auto grow  items-center justify-center w-full  ">
                <a target="blank" href="https://t.me/cloudaxofficial">
                  <FontAwesomeIcon icon={faTelegram} />{" "}
                </a>
                <a target="blank" href="https://twitter.com/CloudaxHQ">
                  {" "}
                  <FontAwesomeIcon icon={faTwitter} />{" "}
                </a>
                <a target="blank" href="https://github.com/cloudaxglobal">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>

      <main>{children}</main>

      <footer className="bg-[black] py-1 w-full  text-white">
        <div className="w-3/4 md:w-[30rem] lg:w-[40rem] mx-auto mt-[2rem] mb-[6rem] text-center">
          <h4 className=" text-[1.5rem] md:text-[2rem] landing lg:text-[4rem] leading-tight mb-6">
            Join The Cloudax Community Today!
          </h4>
          <div className="mb-5 text-black flex gap-4 w-fit mx-auto">
            <a
              href="https://t.me/cloudaxofficial"
              className=" flex text-white items-center justify-center w-[3.5rem] h-[3.5rem] rounded-full  border hover:border-2 border-[#ffffff8d]"
            >
              <FontAwesomeIcon className="text-xl " icon={faTelegram} />
            </a>
            <a
              href="https://github.com/cloudaxglobal"
              className=" flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-full text-white border hover:border-2 border-[#ffffff8d]"
            >
              <FontAwesomeIcon className="text-xl" icon={faGithub} />{" "}
            </a>
            <a
              className="flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-full text-white border hover:border-2 border-[#ffffff8d]"
              target="blank"
              href="https://twitter.com/CloudaxHQ"
            >
              <FontAwesomeIcon className="text-xl" icon={faTwitter} />
            </a>
          </div>
        </div>

        <div className="w-11/12 mx-auto lg:w-3/4 mb-14">
          <div className="flex justify-center gap-0 md:justify-around items-center rounded-[40px] h-[10rem]  lg:h-[7rem] bg-[#80808016]   flex-wrap">
            <img
              onClick={navigateHome}
              className=" w-[8rem] "
              src={logo}
              alt="logo"
            />
            <div className="flex w-full lg:w-fit gap-x-6 gap-y-2 justify-center lg:justify-start flex-wrap lg:flex-nowrap  lg:gap-10  text-[#ffffff89]">
              <a href="http://cloudaxhq.gitbook.io/">
                Whitepaper
              </a>
              <a href="https://spotlight.cloudax.io" target="blank">
                Blog
              </a>
              <Link className="whitespace-nowrap" to="/terms">
                Terms Of Use
              </Link>
              <Link className="whitespace-nowrap" to="/privacy">
                Privacy Policy
              </Link>
              <a href="https://academy.cloudax.io/" target="blank">
                Academy{" "}
              </a>
            </div>
          </div>
          <p className="whitespace-nowrap text-[#ffffff89] mx-auto w-fit mt-[5rem]">
            <FontAwesomeIcon icon={faCopyright} /> {year} Cloudax.{" "}
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Layout;
